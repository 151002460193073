﻿import { global } from '../Common/saop-common';
import { SaopServicesCore } from '../Core/saop-services-core'

export class SaopMealApplicationFormView {
    private _libName: string;

    constructor(viewId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopMealApplicationFormView");

        this._libName = libName;
    }

    initView() {
        global.ConsoleLogDegug("SaopMealApplicationFormView.initView");
    }

    initViewControls() {
        global.ConsoleLogDegug("SaopMealApplicationFormView.initViewControls");
        let _this = this;
        $('#btnNext').on('click', function (e) {
            _this.submitForm();
        });
        $('#btnCancel').on('click', function (e) {
            _this.goToList();
        });
    }

    refreshForm(sifraOtroka: string): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.refreshForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/Form.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/Form"
            , data: {
                sifraOtroka: sifraOtroka
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    refreshFilledForm(idPrijave: string): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.refreshForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/Form.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/FilledForm"
            , data: {
                idPrijave: idPrijave
            }
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    submitForm(): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.submitForm");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;
        let data = $('form').serialize(); // or use the id of the form is you have given it one

        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationView.ExecuteAction.MealApplication/SubmitForm.afterDone");
            if (response.success) {
                let idPrijave = response.viewContent;
                _this.goToPreview(idPrijave);
            }
            else {
                $("#mealApplicationDiv").html(response.viewContent);
                _this.initViewControls();
            }
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/FormSubmit"
            , data: data
            , type: "POST"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    goToPreview(idPrijave: bigint): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.goToPreview");
    }

    goToList(): void {
        global.ConsoleLogDegug("SaopMealApplicationFormView.goToList");
    }
}


