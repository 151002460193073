﻿/// <reference path="./@types/saop_core/saopCommonServices.d.ts" />
/// <reference path="./@types/saop_core/saopTranslationsServices.d.ts" />
//import * as icSaopCore from "./@types/saop_core/index"
//import * as icSaopCore from './@types/saop_core/index'
//import * as saopCommonServices from "./@types/saop_core/saopCommonServices"

import { global } from './Common/saop-common';
import { SaopInputMask } from "./Components/saop-inputmask";
import { SaopSettingsView } from "./Settings/saop-settings-view";
import { SaopChangePwdView } from './Account/saop-change-pwd-view';
import { SaopViewSpinner } from './Components/saop-view-spinner';
import { SaopMealApplicationView } from './MealApplication/saop-meal-application-view';



export function echo(): void {
    icSaopCore.echoTest("test");
}

// export function checkCompatibility(): boolean {
//     return icSaopCore.checkCompatibility();
// }

// export function initClientLogModeValue():void {
//     icSaopCore.initClientLogModeValue();
// }

// export function initSiteBaseUrlValue():void {
//     icSaopCore.initSiteBaseUrlValue();
// }

// export function initializeSiteJS():void {
//     icSaopCore.initializeSiteJS();
// }

// export function setFirstControlToFocus(formId: string):void {
//     icSaopCore.setFirstControlToFocus(formId);
// }

export function initClientLogModeValue() {
    global.debugMode = global.GetClientLogModeStorageValue();
}

export function initSiteBaseUrlValue() {
    global.siteBaseUrl = global.getSiteBaseUrl()
}

export function initializeDebugMode() {
    global.ConsoleLogDegug("initializeDebugMode");

    document.addEventListener('keydown', function (e) {
        if (e.ctrlKey && e.altKey && e.keyCode == 79) {
            // CTRL + ALT + O
            global.SetClientLogModeStorageValue();
            global.debugMode = global.GetClientLogModeStorageValue();
        }
    });
}

export class SaopInputMaskEx extends SaopInputMask { };

export class SaopSettingsViewEx extends SaopSettingsView { };

export class SaopChangePwdViewEx extends SaopChangePwdView { };

export class SaopViewSpinnerEX extends SaopViewSpinner { };

export class SaopMealApplicationViewEx extends SaopMealApplicationView { };



