﻿import { global } from '../Common/saop-common';
import { SaopViewForm } from "../Core/saop-view-form";
import { SaopWfAction } from "../Core/saop-view-form";


export class SaopMealApplicationBeneficiaryView extends SaopViewForm {
    private _formId: string;

    public sifraOtroka: string;

    constructor(viewId: string, libName: string = "") {
        super(viewId, libName);

        this.fullTitle = "Prijava na šolsko prehrano";

        this.addRecordEndpoint = "";
        this.editRecordEndpoint = "MealApplication/Beneficiary";
        this.deleteRecordEndpoint = "";

    }

    onBtnOkClick(sender: any): void {
        global.ConsoleLogDegug("SaopMealApplicationBeneficiaryView.onBtnOkClick");

        let selectedCode = $("#SelectedCode").val().toString();

        this.sifraOtroka = selectedCode;
        //this.goStraightToForm(selectedCode);
    }
}


