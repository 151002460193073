﻿import { global } from '../Common/saop-common';
import { SaopWfAction } from "../Core/saop-view-form";
import { SaopServicesCore } from '../Core/saop-services-core'
import { SaopMealApplicationBeneficiaryView } from "./saop-meal-application-beneficiary-view";
import { param } from 'jquery';

export class SaopMealApplicationListView {
    private _libName: string;

    private _beneficiaryForm: SaopMealApplicationBeneficiaryView;
    public selectedCode: string;
    private idPrijave: string;


    constructor(viewId: string, libName: string = "") {
        global.ConsoleLogDegug("SaopMealApplicationListView");

        this._libName = libName;
    }

    initView() {
        global.ConsoleLogDegug("SaopMealApplicationListView.initView");

        this._beneficiaryForm = new SaopMealApplicationBeneficiaryView("#mealApplicationView001", this._libName);
        this._beneficiaryForm.onBtnOkClick = this.onBtnOkClickEx.bind(this);

        let _this = this;

        this.refreshList();
    }

    initViewControls() {
        global.ConsoleLogDegug("SaopMealApplicationListView.initViewControls");
        let _this = this;
        $('#btnNew').on('click', function (e) {
            if ($("#SteviloOtrok").val() > 1) {
                _this._beneficiaryForm.showViewForm(SaopWfAction.wfaEditRecord, {});
            }
            else {
                let selectedCode = $("#PrviOtrok").val().toString();
                _this.goToForm(selectedCode);
            }
                
        });
        $('.btnDocument').on('click', function (e) {
            _this.idPrijave = this.getAttribute("data-prijava-id");
            _this.refreshList();
        });
    }

    refreshList(params: any = null): void {
        global.ConsoleLogDegug("SaopMealApplicationListView.refreshList");

        let saopServicesCore = new SaopServicesCore();
        let _this = this;

        params = {
            idPrijave : this.idPrijave
        }
        saopServicesCore.afterDone = function (response) {
            global.ConsoleLogDegug("SaopMealApplicationListView.ExecuteAction.MealApplication/List.afterDone");
            $("#mealApplicationDiv").html(response);
            _this.initViewControls();
        };
        saopServicesCore.ExecuteAction({
            action: "MealApplication/List"
            , data: params
            , type: "GET"
            , spinnerDivId: "#mealApplicationLoading"
        });
    }

    goToForm(sifraOtroka: string): void {
        global.ConsoleLogDegug("SaopMealApplicationListView.goToForm");
    }

    public onBtnOkClickEx(sender: any): void {
        global.ConsoleLogDegug("SaopMealApplicationListView.onBtnOkClickEx");

        let selectedCode = $("#SelectedCode").val().toString();

        this.goToForm(selectedCode);
    }

}


